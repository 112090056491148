"use client";

import { Breadcrumb } from "react-bootstrap";

export type BreadcrumbItem = {
    label: string;
    url: string;
};

export function Breadcrumbs({ items }: { items: BreadcrumbItem[] }): JSX.Element {
    return (
        <Breadcrumb key="breadcrumb">
            {items.map((item, index) => (
                <Breadcrumb.Item href={item.url} key={"breadcrumb-" + index} active={index + 1 == items.length}>
                    {item.label}
                </Breadcrumb.Item>
            ))}
        </Breadcrumb>
    );
}